import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/components/PaginaPrincipal')
  },
  {
    path: '/busqueda',
    name:'busqueda',
    component: () => import('@/components/Busqueda')
  },
  {
    path: '/evaluacion',
    name: 'evaluacion',
    component: () => import('@/components/ListaDocs')
  },
  {
    path: '/graficos',
    name: 'graficos',
    component: () => import('@/components/chart/ChartContenedor') 
  },
  {
    path: '/mapa',
    name: 'mapa',
    component: () => import('@/components/Mapa') 
  },
  {
    path: '/detalles/',
    name: 'detalles',
    component: () => import('@/components/FichaProyecto')
  },
  {
    path: '/detalles/sancion',
    name: 'detalles-sancion',
    component: () => import('@/components/FichaProyectoSancion')
  },
  {
    path: '/expediente/:id_expediente',
    name: 'expediente',
    component: () => import('@/components/FichaProyecto')
  },
  {
    path: '/avanzada',
    name: 'avanzada',
    component: () => import('@/components/BusquedaAvanzada')
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import('@/components/Registro')
  },
  {
    path: '/verificar-correo/:uidb64/:token',
    name: 'verificar-correo',
    component: () => import('@/components/VerificarCorreo'),
    props: true
  },
  {
    path: '/nueva-constrasena/:uidb64/:token/:cambioContrasena',
    name: 'nueva-contrasena',
    component: () => import('@/components/VerificarCorreo'),
    props: true
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/cambio/contrasena',
    name: 'cambio-contrasena',
    component: () => import('@/components/CambioContrasena'),
  },
  {
    path: '/busqueda/snifa',
    name: 'busquedaSnifa',
    component: () => import('@/components/BusquedaSnifa'),
  },
  {
    path: '/busqueda/hechos',
    name: 'busquedaSnifaHechos',
    component: () => import('@/components/BusquedaSnifaHechos'),
  },
  {
    path: '/avanzada-snifa',
    name: 'avanzada-snifa',
    component: () => import('@/components/BusquedaAvanzadaSnifa'),
  },
  {
    path: '/administracion/empresas',
    name: 'administracion-empresas',
    component: () => import('@/components/AdministracionEmpresas'),
  },
  {
    path: '/administracion/usuarios',
    name: 'administracion-usuarios',
    component: () => import('@/components/AdministracionUsuarios'),
  },
  {
    path: '/administracion/ingresos',
    name: 'administracion-ingresos',
    component: () => import('@/components/AdministracionIngresos'),
  },
  {
    path: '/administracion/novedades',
    name: 'administracion-novedades',
    component: () => import('@/components/AdministracionNovedades.vue'),
  },
  {
    path: '/guias-sea',
    name: 'guias-sea',
    component: () => import('@/components/BusquedaGuias'),
  },
  {
    path: '/busqueda/pertinencia',
    name: 'busqueda-pertinencia',
    component: () => import('@/components/BusquedaPertinencia.vue'),
  },
  {
    path: '/guias-snifa',
    name: 'guias-snifa',
    component: () => import('@/components/BusquedaGuiasSnifa.vue'),
  },
  {
    path: '/avanzada-pertinencia',
    name: 'avanzada-pertinencia',
    component: () => import('@/components/BusquedaAvanzadaPertinencia.vue'),
  },
  {
    path: '/administracion/dashboard',
    name: 'administracion-dashboard',
    component: () => import('@/components/Dashboard.vue'),
  },
  {
    path: '/avanzada-empresa',
    name: 'avanzada-empresa',
    component: () => import('@/components/BusquedaAvanzadaEmpresa.vue'),
    beforeEnter: (to, from, next) => {
      const empresa_id = localStorage.getItem('empresa_id')
      if (empresa_id == 23 || localStorage.getItem('super')) {
        next()
      } else {
        next('/home')
      }
    }
  },
  {
    path: '/documento-empresa',
    name: 'documento-empresa',
    component: () => import('@/components/DocumentoEmpresa.vue'),
    beforeEnter: (to, from, next) => {
      const empresa_id = localStorage.getItem('empresa_id')
      if (empresa_id == 23 || localStorage.getItem('super')) {
        next()
      } else {
        next('/home')
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
